<template>
  <div>
    <v-sheet class="mx-auto mt-0 pa-0" elevation="2">
      <v-col cols="12">
          <span class="subtitle-1 pr-2 black--text">Selecione o Tipo de Solicitação</span>
        <v-row>
          <v-col md="12">
            <v-text-field
              outlined
              append-icon="mdi-magnify"
              :disabled="loading"
              :loading="loading"
              placeholder="Pesquisar"
              dense
              v-model="search"
            ></v-text-field>
            <div v-if="loading || loadingList">
              <v-skeleton-loader
                type="card-heading, card-heading, card-heading"
              ></v-skeleton-loader>
            </div>
            <v-list-item-group
              color="primary"
              v-if="filteredSolicitationTypes.length && !loading && !loadingList"
            >
              <v-list-item v-for="(item, i) in filteredSolicitationTypes" :key="i">
                <v-list-item-content @click="goToWizard(item.id)">
                  <v-list-item-title>
                    <strong>{{ item.display_name }}:</strong> {{ item.description }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                      v-html="item.provider.name"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-alert
              v-if="!loading && !filteredSolicitationTypes.length && !loadingList"
              border="left"
              dense
              colored-border
              type="warning"
              >Nenhum tipo de solicitação encontrado!
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-sheet>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      loading: true,
      search: ""
    };
  },
  computed: {
    ...mapState("solicitationType", ["solicitation_type","list_solicitation_type_tag", "loadingList"]),
    filteredSolicitationTypes() {
      if (!this.list_solicitation_type_tag.data || this.list_solicitation_type_tag.data.length < 0) {
        return false;
      }
      return this.list_solicitation_type_tag.data.filter(solicitationType => {
        return (
          solicitationType.display_name
            .toLowerCase()
            .match(this.search.toLowerCase()) !== null
        );
      });
    }
  },
  methods: {
    ...mapActions("solicitationType", ["loadSolicitationTypes"]),
    goToWizard(id_solicitation_type) {
      this.$router.push({
        name: "solicitation-wizard",
        params: { id: id_solicitation_type, email_id: this.$route.params.email_id, emailRequester: this.$route.params.emailRequester }
      });
    }
  },
  async mounted() {
    this.loading = false;
  }
};
</script>
<style scoped></style>
